import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Content from "../components/content"

export const pageQuery = graphql`
  query DatabaseQuery($id: String!){
		markdownRemark(id: { eq: $id }) {
      id
			html
			excerpt(pruneLength: 140)
      frontmatter {
        title
		abstract
		usage
		expression
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 600, maxHeight: 400, quality: 80, srcSetBreakpoints: [960, 1440]
					) {
              ...GatsbyImageSharpFluid
            }
            sizes {
              src
            }
          }
        }
      }
    }
  }
`
const DatabasePage = ({ data }) => {
	const { markdownRemark } = data // data.markdownRemark holds your post data
    const { frontmatter, html, excerpt } = markdownRemark
    const Image = frontmatter.featuredImage ? frontmatter.featuredImage.childImageSharp.fluid : ""

	return (
		<Layout className="page">
			<SEO
				title={frontmatter.title}
				description={excerpt}
			/>
			<div className="wrapper">
				{Image ? (
          		  <Img 
          		    fluid={Image} 
          		    alt={frontmatter.title + ' - Featured image'}
          		    className="top-page-image"
					fadeIn={false}
          		  />
          		) : ""}
				<h1>{frontmatter.title}</h1>
				<article className="database-section">
					<h3>概要</h3>
					<Content source={frontmatter.abstract} />
				</article>
				<hr/>
				<article className="database-section">
					<h3>使い方</h3>
					<Content source={frontmatter.usage} />
					<div className="text-align-center">
						<Link to="https://dict.erabumuni.com/words" className="button">データベースを見る</Link>
					</div>
				</article>
				<hr/>
				<article className="database-section">
					<h3 id="expression">表記について</h3>
					<Content source={frontmatter.expression}/>
					<div
        			  className="expression-table"
        			  dangerouslySetInnerHTML={{ __html: html }}
        			/>
				</article>
			</div>
		</Layout>
	)
}

export default DatabasePage
